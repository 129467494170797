import React, { useContext } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'
import { SiteLink } from './SiteLink'
import { LayoutContext } from '../contexts'

const RichText = ({ json }) => {
  const { node_locale } = useContext(LayoutContext)

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({
        data: {
          target: {
            fields: { file, title }
          }
        }
      }) => {
        return (
          <img
            alt={title[node_locale] ? title[node_locale] : title.en}
            src={file[node_locale] ? file[node_locale].url : file.en.url}
          />
        )
      },
      [INLINES.HYPERLINK]: ({
        data: { uri }
      }, children) => (
        <>
          {uri.indexOf('getsuperfluid.com/') > -1 ? (
            <RichTextSiteLink slug={uri.split('.com/')[1]}>
              {children}
            </RichTextSiteLink>
          ) : (
            <RichTextLink
              target='blank'
              href={uri}
              rel='noopener noreferrer'
            >
              {children}
            </RichTextLink>
          )}
        </>
      )
    }
  }

  return documentToReactComponents(json, options)
}

const RichTextLink = styled.a`
  text-transform: initial;
  cursor: pointer;
`

const RichTextSiteLink = styled(SiteLink)`
  text-decoration-line: underline;
  font-weight: bold;
  text-transform: initial;
`

export default RichText
